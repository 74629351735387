<template>
	<div class="app-container" style="background: #fff;padding: 15px;">
		<!-- filter -->
		<div class="filter-container" style="position: relative;padding-right: 140px;">
			<!-- 关键字搜索 -->
			<!-- style="display: flex;align-items: center;" -->
			<div class="filter-item" >
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" @keyup.enter.native="handleFilter"  placeholder="活动名称" style="width: 300px;"></el-input>
			</div>
			<!--优惠券类型  -->
			<div class="filter-item" style="margin-left: 10px;">
				<label class="label">活动类型: </label>
				<el-select v-model="type" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- 优惠券状态 -->
			<div class="filter-item" style="margin-left: 10px;">
				<label class="label">活动状态: </label>
				<el-select v-model="status" placeholder="请选择">
					<!-- <el-option label="全部" ></el-option> -->
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="handleFilter">查询</el-button>
				<button-permissions :datas="'addGiveCoupons'">
					<el-button type="primary" style="margin-left:30px;width:100px;" @click="handleTableAdd()">添加</el-button>
				</button-permissions>
			</div>
			
			<div style="position: absolute;right: 20px;top: 0px;">
				<experience-code></experience-code>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column prop="ActivityName" label="活动名称"></el-table-column>
				<el-table-column label="活动时间" width="250px">
					<template slot-scope='scope'>
						<div style="width: 140px;">{{scope.row.ActivityTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="ActivityCouponGiftTypeName" label="类型"></el-table-column>
				<el-table-column prop="ActivityStateName" label="状态"></el-table-column>
				<el-table-column prop="GetNum" label="发放人数"></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
				<el-table-column label="操作" width="180px">
					<template slot-scope="scope">
						<div class="table-button" @click="handleTableView(scope.row.ActivityFullId)" v-if="scope.row.ActivityState==3">查看</div>

						<button-permissions :datas="'editGiveCoupons'" v-if="scope.row.ActivityState==1 || scope.row.ActivityState==2">
							<div class="table-button" @click="handleTableEdit(scope.row.ActivityFullId)" >编辑</div>
						</button-permissions>

						<button-permissions :datas="'cancelGiveCoupons'"  v-if="scope.row.ActivityState==1 || scope.row.ActivityState==2">
							<div class="table-button" @click="handleTableFail(scope.row.ActivityFullId)">使终止</div>
						</button-permissions>

						<button-permissions :datas="'copyGiveCoupons'" >
							<div class="table-button" @click="handleTableCopy(scope.row)">复制</div>
						</button-permissions>

						<button-permissions :datas="'deleteGiveCoupons'"  v-if="scope.row.ActivityState==3">
							<div class="table-button" style="color:#F56C6C" @click="handleTableDelete(scope.row.ActivityFullId)">删除</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40, 50]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<!-- add-dialog -->
		<el-dialog title="添加发券宝" :visible.sync="editDialog" width="40%" class="dialog" :before-close="handleClose">
			<div class="content">
				<div class="left" style="white-space: nowrap;">我要添加</div>
				<div class="right">
					<el-radio v-model="editRadio" label="1">新客进店有礼</el-radio>
					<div class="text">从未在您的店铺支付成功过一笔订单，且没有领过本次活动发放的新客礼包；
						同一个活动，在活动时间内，仅领取一次</div>
					<el-radio v-model="editRadio" label="2" style="margin-top: 20px;">老客进店有礼</el-radio>
					<div class="text">老客进店赠送礼包；同一个活动，在活动时间内，每天进店可领取，仅领取一次</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button class="button" @click="addCouponSure" type="primary">确定</el-button>
			</span>
		</el-dialog>


	</div>
</template>

<script>
	import coupon from '@/api/other';
	
	import experienceCode from '@/components/experienceCode';

	import {
		CouponGiftList,
		CouponGiftEdit
	} from "@/api/wyUsedInterface.js"

	import { async } from 'q';import buttonPermissions from '@/components/buttonPermissions';

	export default{
		components: {
			buttonPermissions,
			experienceCode
		},
		data() {
			return {
				searchKey: '',
				type: null,
				typeOptions: [{
						value: 0,
						label: '新客进店有礼'
					},
					{
						value: 1,
						label: '老客进店有礼 '
					}
				],
				editDialog: false,
				editRadio: '1',
				status: 0,
				statusOptions: [{
						value: 0,
						label: '全部'
					},{
						value: 1,
						label: '未开始'
					},
					{
						value: 2,
						label: '进行中'
					},
					{
						value: 3,
						label: '已结束'
					}
				],
				tableData: [],
				page: {
					total: 0,
					current: 1,
					size: 20
				},
				loading:false
			}
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true;
				try {
					let data = {
						ActivityName: this.searchKey, // 搜索关键字
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size,
						ActivityCouponGiftType: this.type,
						ActivityState: this.status
					}
					let result = await CouponGiftList(data);

					this.page.total = result.Result.Total;
					this.tableData = result.Result.Results || [];

				} catch (error) {
					this.loading = false
					console.log(error);
				} finally {
					this.loading = false;
				}
			},
			handleFilter() {
				this.getList()
			},
			addCouponSure() {
				// this.editRadio
				var types = parseInt(this.editRadio)-1
				this.$router.push({
					path: '/customer/issueCoupon/editIssueCoupon',
					query: {
						type: types,
						btnType:0,//0为添加  1为编辑   2为复制  3为查看
					}
				})
			},
			// table查看
			handleTableView(id) {
					this.$router.push({
						path: '/customer/issueCoupon/editIssueCoupon',
						query: {
							ActivityFullId: id,
							btnType:3,//0为添加  1为编辑   2为复制  3为查看
						}
					})
			},
			handleTableCopy(val){
				this.$router.push({
					path: '/customer/issueCoupon/editIssueCoupon',
					query: {
						type: val.ActivityCouponGiftType,
						ActivityFullId: val.ActivityFullId,
						btnType:2,//0为添加  1为编辑   2为复制  3为查看
					}
				})
			},
			// 编辑，添加
			handleTableEdit(id) {
				this.$router.push({
					path: '/customer/issueCoupon/editIssueCoupon',
					query: {
						ActivityFullId: id,
						btnType:1,//0为添加  1为编辑   2为复制  3为查看
					}
				})
			},
			
			handleTableAdd(){
				this.editDialog = true
			},
			
			// table使终止
			handleTableFail(id) {
				
				this.$confirm('活动终止后，你将不可再对这场活动进行编辑，是否确认使终止？', '提示', {
					confirmButtonText: '确认失效',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.failCoupon(id)
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消失效'
					});
				}).finally(() => {
				})
			},
			
			async failCoupon(id){
				try{
					let data = {ActivityFullId:id}
					let result = await coupon.activityMarkdownGiftIsOpen(data)
					if(result.IsSuccess){
						this.$message({showClose: true,
							type: 'success',
							message: '终止成功!'
						});
						this.getList()
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			
			async deleteCoupon(id){
				try{
					let data = {
						ActivityFullId:id
					}
					let result = await coupon.activityMarkdownGiftDel(data)
					if(result.IsSuccess){
						this.$message({showClose: true,
							type: 'success',
							message: '删除成功!'
						});
						this.getList()
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			
			// table数据
			handleTableData(id) {
				this.showDataDialog = true
			},
			// table删除
			handleTableDelete(id) {

				this.$confirm('是否确认删除活动？删除后不可恢复', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.deleteCoupon(id)

				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
				})
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList();
			},
			handleClose() {
				this.editDialog = false
			},
		}
	}
</script>

<style lang="less" scoped>
	.table-button {
		color: #409EFF;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	.dialog {
		width: 100%;

		::v-deep .el-dialog__header {
			border-bottom: 1px solid #ddd;
		}

		.content {
			padding: 0 30px;
			display: flex;
			flex-direction: row;

			.right {
				margin-left: 20px;
			}

			.text {
				margin-left: 20px;
				font-size: 12px;
				color: #909399;
				line-height: 20px;
			}
		}

		.button {
			margin: 0 auto;
			width: 200px;
		}
	}

	.data-dialog {
		.value {
			line-height: 50px;
			font-size: 24px;
			color: #000;
		}

		.item {
			color: #666;
			margin-top: 10px;
		}
	}
</style>
